.events{
    background-color: #1F1F1F;
    padding: 3rem 3rem;
}

.events_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.events_header_title{
    font-size: 2.4rem;
    text-transform: uppercase;
    color: #f1f1f1;
}

.events_header_link{
    color: #57F09E;
    font-size: 1.3rem;
    text-decoration: underline;
    cursor: pointer;
}
.overflow-scrolling-events.events_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: calc(100vw - 4rem);
}
.event_item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    /*max-width: 360px;*/
    min-width: calc(30vw);
    padding: 3.6rem;
    border-right: 2px solid #f1f1f1;
    max-height: 490px;
}
.event_item:last-child{
    /*padding-right: 2.5rem;*/
    border-right: none;
}
.event_item img{
    width: 100%;
    min-height: 150px;
    max-height: 250px;
}
.event_info{
    min-height: 200px;
}
.event_main_line{
    height: 90%;
    min-height: 350px;
    width: 2px;
    background-color: #f1f1f1;
    border-radius: 10px;
    margin: 0 2rem;
}

.event_name{
    padding: 0;
    margin: 1.7rem 10px 0 0;
    color: #f1f1f1;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.event_date{
    padding: 0;
    margin: 10px 0;
    text-transform: uppercase;
    color: #f1f1f1;
}

@media all and (max-width: 480px){
    .events{
        padding: 1rem 0;
    }
    .events_header_title{
        margin:1rem;
        font-size: 2.2rem;
    }
    .overflow-scrolling-events.events_list{
        width: calc(100vw - 1rem);
    }
    .event_item{
        min-width: calc(67vw - 2rem);
        padding: 0 1rem;
        border-right: none;
    }
    .event_item img{
        max-width: 280px;
        width: 100%;
        min-height: 90px;
        max-height: 170px;
    }
    .event_name{
        font-size: 1.4rem;
        margin: 1rem 0;
    }


    .event_link{
        max-width: 50px;
        background-color: #1F1F1F;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        padding: 0;
        cursor: pointer;
        outline: none;
    }

}