.hedader_togg_side{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    background-color: #1F1F1F;
    width: 100%;
    height: auto;
    border-left: 1px solid #f1f1f1;
}
.hedader_togg_side_rot{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /*min-width: 440px;*/
    transform: rotate(90deg);
}
label.hedader_togg_side_label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}
.hedader_togg_side_tittle{
    text-transform: uppercase;
    font-size: 2.2rem;
    color: #f1f1f1;
    width: 100%;
    margin: 0 1rem;
}

input.hedader_togg_side_input {
    opacity: 0;
    width: 0;
    height: 0;
}

span.hedader_togg_side_span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2c3e50;
    transition: 0.3s;
    border-radius: 30px;
}

span.hedader_togg_side_span:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

input.hedader_togg_side_input:checked + span.hedader_togg_side_span {
    background-color: #2de278;
}

input.hedader_togg_side_input:checked + span.hedader_togg_side_span:before {
    transform: translateX(29px);
}
@media all and (max-width: 480px){
    .hedader_togg_side_rot{
        transform: rotate(0deg);
        min-width: auto;
    }
    .aff_side{
        margin: 0 10px 0 0;
    }
    .adv_side{
        margin: 0 0 0 10px;
    }
    .hedader_togg_side_tittle{
        font-size: 1.2rem;
        margin: 0 ;
        width: auto ;
    }
    .hedader_togg_side{
        border-left: none;
        border-top: 1px solid #f1f1f1;
    }
}
