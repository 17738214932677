.form_submit{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem;
    width: 100%;
}

.form_box{
    margin: 1rem 0;
    width: 100%;
    position: relative;
}

.form_label{
    position: relative;
    top: 40%;
    transition: 0.4s;
    color: #f1f1f1;
}
.form_label.active{
    top: 0;
    transition: 0.4s;
}

.form_input{
    display: flex;
    width: 100%;
    margin: 0;
    background-color: inherit;
    border: none;
    border-bottom: 2px solid #f1f1f1;
    outline: none;
    padding: 16px 0;
    font-size: 1.4rem;
    color: #f1f1f1;
}

input.form_input:-webkit-autofill{
    background-color: #1F1F1F!important;
    color: #f1f1f1!important;
    font-size: 1.4rem;
    transition: font-size, color, background-color 5000s ease-in-out 0s;

}


.form_input.error{
    border-bottom: 2px solid red;
}

.form_submit_btn{
    color: #2de278;
    font-size: 1.3rem;
    text-decoration: underline;
    cursor: pointer;
    background-color: inherit;
    border: none;
    outline: none;
}

.popup_send{
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}
.popup_send_box{
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #1f1f1f;
    border-radius: 12px;
    pointer-events: none;
}
.popup_send_box_title{
    color: #f1f1f1;
    text-transform: uppercase;
    font-size: 4rem;
    margin: 0.5rem;
    text-align: center;
}
.popup_send_box_text{
    color: #f1f1f1;
    text-transform: uppercase;
    font-size: 2.4rem;
    margin: 0.5rem;
    text-align: center;

}

@media all and (max-width: 480px){
    .form_submit{
        padding: 2rem;
    }
    .form_submit_btn{
        font-size: 1.8rem;
        text-transform: capitalize;
        margin-top: 20px;
    }
    .popup_send_box{
        padding: 1rem;
    }
}
