.main_about_bottom{
    display: grid;
    grid-template-columns: 2fr 5fr;
    background-color: #1F1F1F;
    padding: 1rem;
}

.main_about_bottom_box-right{
    font-size: 1.2rem;
    color: #f1f1f1;
    padding-right: 4rem;
}

.main_about_bottom_box-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

.main_about_bottom_box_title{
    margin: 0;
    color: #f1f1f1;
    font-size: 1.8rem;
    text-transform: uppercase;
}

.main_about_bottom_link{
    color: #2de278;
    font-size: 1.3rem;
    text-decoration: underline;
    cursor: pointer;
}

@media all and (max-width: 480px){
    .main_about_bottom{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .main_about_bottom_box-left{
        padding: 1rem 1rem 0;
    }
    .main_about_bottom_box-right{
        padding: 0 1rem 1rem;
    }
}