.page_about_main_info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1F1F1F;
}

.page_about_main_info_title-box{
    flex: 40% 1;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    min-height: 400px;
    padding: 0 2rem;
}
.page_about_main_info_title{
    font-size: 2rem;
    color: #f1f1f1;
    font-weight: 600;
    text-transform: uppercase;
}

.page_about_main_info_text-box{
    flex: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #f1f1f1;
}
.page_about_main_info_img-box{
    display: flex;
    justify-content: flex-start;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    width: 100%;
}
.page_about_main_info_img{
    width: 100%;
}


.page_about_main_info_text-item{
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    width: 100%;
    border-top: 1px solid #f1f1f1;
}
.page_about_main_info_text{
    display: flex;
    flex:65% 0;
    color: #f1f1f1;
    width: 100%;
    text-align: right;
    font-size: 1.2rem;
}

@media all and (max-width: 480px){
    .page_about_main_info{
        flex-direction: column;
    }
    .page_about_main_info_title-box{
        min-height: auto;
    }
    .page_about_main_info_title{
        margin: 2rem 0;
        font-size: 1.1rem;
    }
    .page_about_main_info_text-box{
        border-left: none;
    }
    .page_about_main_info_img-box{
        padding: 0;
        width: 100%;
    }
    .page_about_main_info_text-item{
        border-top: none;
        padding: 1.5rem;
    }
    .page_about_main_info_text{
        flex: 100%;
        font-size: 1.1rem;
    }
}