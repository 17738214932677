.company_contacts_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    width: 100%;
}

.company_contacts_list_item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-right: 1px solid #f1f1f1;
    padding: 2rem 4rem;
}
.company_contacts_list_item:last-child{
    border-right: none;
}

.company_contacts_list-title{
    font-size: 2.4rem;
    color: #f1f1f1;
    text-transform: uppercase;
    margin: 0;
}
.company_contacts_list-example{
    font-size: 1.4rem;
    color: #f1f1f1;
    text-transform: uppercase;
    text-decoration: underline;
    margin: 1rem 0;
    /*overflow-wrap: break-word;*/
    width: 100%;
    /*max-width: 320px;*/
    min-height: 50px;
}

.company_contacts_list-href{
    color: #2de278;
    font-size: 1.3rem;
    text-decoration: underline;
    cursor: pointer;
}

@media all and (max-width: 480px){
    .company_contacts_list{
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }
    .company_contacts_list_item{
        border-right: none;
        border-bottom: 1px solid #f1f1f1;
    }
    .company_contacts_list_item:last-child{
        border-bottom: none;
    }
    .company_contacts_list-title{
        font-size: 1.6rem;
    }
    .company_contacts_list-example{
        font-size: 1.1rem;
    }
}