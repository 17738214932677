.reviews{
    background-color: #1F1F1F;
    padding: 4rem 3rem;
    border-bottom: 1px solid #f1f1f1;
}

.reviews_box-title{
    margin: 0;
    padding: 0;
    font-size: 2.4rem;
    color: #f1f1f1;
    text-transform: uppercase;
}

@media all and (max-width: 480px){
    .reviews{
        padding: 2rem 0;
    }
    .reviews_box-title{
        font-size: 2.2rem;
        margin-left: 1.3rem;
    }
}