.header{
    background-color: #1F1F1F;
    width: 100%;
    height: 80px;
    display: grid;
    grid-template-columns: 300px auto 170px;
    align-content: center;
    border-bottom: 1px solid #D9D9D9;
}
.logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #D9D9D9;
    /*padding: 0.7rem;*/
}
.logo img{
    width: 100%;
    max-width: 246px;
    padding: 1rem;
}

.logo h1{
    color: #f1f1f1;
    font-size: 2rem;
    cursor: pointer;
    margin: 0;
}
.logo span{
    margin: 0;
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #a1147a;
}

.navbar_links{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem 1rem 0;
}

.navbar_links_container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.navbar_links_container-item:after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 1em;
    width: 100%;
    border-bottom: 2px solid;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}

.navbar_links_container-item:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    box-sizing: border-box;
    color: #2de278;
}
.navbar_links_container-item:hover {
    color: #2de278!important;
}
.navbar_links_container-item{
    font-size: 1rem;
    background-color: rgba(25,25,25,0);
    color: #f1f1f1;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 1.5rem;
    position: relative;
}

.navbar_links_container-item.active, .active_lang{
    color: #2de278;
}
.navbar_links_container:last-child{
    margin-right: 2.5rem;
}

.lang{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-left: 1px solid #D9D9D9;
}

.lang h1{
    color: #f1f1f1;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-left: 1rem;
    cursor: pointer;
}
.navbar_link_mob{
    display: none;
}
@media all and (max-width: 480px){
    .header{
        grid-template-columns: 110px 1fr;
        height: auto;
    }

    .logo img{
        width: auto;
        /*max-width: 40px;*/
        max-height: 62px ;
        padding: 0;
    }

    .lang{
        padding: 0.6rem 1rem;
    }

    .logo h1{
        font-size: 1.45rem;
    }
    .navbar_links{
        display: none;
    }
    .navbar_link_mob{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .navbar_link_mob{
        width: 50px;
    }
    .lang{
        border-left: none;
        justify-content: flex-end;
        cursor: pointer;
    }
    .lang h1{
        font-size: 1.65rem;
        margin-left: 1rem;
        cursor: pointer;
    }

    .mob_menu{
        position: fixed;
        z-index: 9999999;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #1F1F1F;
        /*max-width: 480px;*/
        /*width: 100%;*/
    }
    .mob_menu-close {
        position: fixed;
        top: 1rem;
        right: 1rem;

    }
    .mob_menu-close img{
        width: 50px;
        height: 50px;
        fill: #f1f1f1;
    }
    .mob_menu_links_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        width: 100%;
        padding-top: 30%;
    }

    .mob_menu_links_container-item{
        border: none;
        background: none;
        font-size: 1.8rem;
        color: #f1f1f1;
        font-weight: bold;
        text-transform: uppercase;
        margin: 1rem;

    }
    .mob_menu_links_container-item.active{
        color: #2de278;
    }
}