.main_about_partners{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-right: 1px solid #f1f1f1;
    /*background-color: #1F1F1F;*/
    background: url("../../images/space.jpg");
    background-size: cover;
}

.main_about_partners_title h1{
    text-align: center;
    color: #f1f1f1;
    font-size: 2.4rem;
    text-transform: uppercase;
}
.main_about_partners_list{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 2rem 0;
}
.main_about_partners_list_item{
    margin: 1rem 0;
}

.main_about_partners_list_item.active img{
    /*fill: #A1147A;*/
    -webkit-filter: brightness(0) saturate(100%) invert(73%) sepia(37%) saturate(820%) hue-rotate(87deg) brightness(92%) contrast(99%);
    filter: brightness(0) saturate(100%) invert(73%) sepia(37%) saturate(820%) hue-rotate(87deg) brightness(92%) contrast(99%);
}

@media all and (max-width: 480px){
    .main_about_partners{
        align-items: flex-start;
        border-right: none;
        border-bottom: 1px solid #f1f1f1;
    }
    .main_about_partners_title{
        /*width: 100%;*/
    }
    .main_about_partners_title h1 {
        text-align: left;
        font-size: 1.8rem;
    }
    /*.main_about_partners_list{*/
    /*    flex-direction: row;*/
    /*    justify-content: flex-start;*/
    /*    align-items: center;*/
    /*    padding: 2rem 0 2rem 3rem;*/
    /*    width: 100%;*/
    /*    overflow-x: scroll;*/
    /*    white-space: nowrap;*/
    /*}*/
    .main_about_partners_list_item {
        display: block;
        width: 200px;
        height: auto;
        margin: 0 2rem 0 0;
    }
    .overflow-scrolling:last-child{
        margin: 0;
    }
    .overflow-scrolling{
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 2rem;
        /*width: 100vw;*/
        width: calc(100vw - 1rem);
    }
}

