.main_about{
    display: grid;
    grid-template-columns: 400px auto;
}

@media all and (max-width: 480px){
    .main_about{
        display: block;
        /*flex-direction: column;*/
        /*align-items: flex-start;*/
        width: 100%;
    }
}
