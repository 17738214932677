.hedader_top{
    display: grid;
    grid-template-columns: 300px auto;
    grid-template-rows: calc(50vh - 40px);
    align-content: center;
    width: 100%;
    /*background-color: #1F1F1F;*/
    background: url("../../images/space.jpg");
    background-size: cover;
}
.hedader_top_cirkles{
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #D9D9D9;
    padding: 1rem;
}
.hedader_top_info{
    padding: 1rem;
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: auto;
    align-content: center;
}
.hedader_top_info_imgs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.hedader_top_info_imgs_item img{
    width: 66px;
    height: 66px;
    margin: 1rem;
}
.hedader_top_info_titles{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
}
.hedader_top_info_title{
    width: 100%;
    font-size: 3.5rem;
    color: #f1f1f1;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
}
.hedader_top_info_title.left{
    grid-area: left;
    text-align: left;
}
.hedader_top_info_title.right{
    grid-area: right;
    text-align: left;
}
.hedader_top_info_title.center{
    grid-area: center;
    text-align: left;
    color: #a1147a;
}

@media all and (max-width: 480px){
    .hedader_top {
        display: grid;
        grid-template-columns: 140px auto;
        grid-template-rows: auto;
    }
    .hedader_top_info{
        grid-template-columns: auto;
    }
    .hedader_top_info_imgs{
        display: none;
    }
    .hedader_top_info_titles{
        display: grid;
        grid-template-areas:
        'left'
        'center'
        'right';
        margin: auto;
        width: 100%;
    }
    .hedader_top_info_title{
        font-size: 1.4rem;
        width: 100%;
    }
    .hedader_top_cirkles img{
        width: 110px;
    }
    .hedader_top_info_title.right{
        font-size: 1.2rem;
    }
}