.main_about_top{
    background-color: #1F1F1F;

    background-size: cover;
    background-position: center right;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem 4rem;
    border-bottom: 1px solid #f1f1f1;
}

.main_about_top_title{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4rem;
    width: 100%;
}
.main_about_top_title h1{
    font-size: 3.3rem;
    text-transform: uppercase;
    color: #f1f1f1;
    text-align: right;
}
.pink_title{
    color: #A1147A;
    margin-left: 1rem;
}

@media all and (max-width: 480px){
    .main_about_top{
        padding: 1rem;
        border-bottom: none;
    }
    .main_about_top_title{
        padding: 0;
    }
    .main_about_top_title h1{
        font-size: 2.2rem;
        margin-bottom: 1rem;
    }

}