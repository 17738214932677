.hedader_bottom{
    display: grid;
    grid-template-columns: 2fr 2fr 0.7fr;
    grid-template-rows: calc(50vh - 40px);
    align-content: center;
    background-color: #1F1F1F;
    border-top: 1px solid #f1f1f1;
}
.hedader_bottom_info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: relative;
}
.hedader_bottom_info_circle_text{
    position: absolute;
    right: -70px;
    top: -67px;
}
.hedader_bottom_info_circle_text img{
    max-width: 140px;
    animation: moveCircleText 24s linear infinite;
}

@keyframes moveCircleText {
    0% {
        transform: rotate(0deg);

    }
    100% {
        transform: rotate(360deg);
    }
}

.hedader_bottom_info_box{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
.hedader_bottom_info_box_text{
    font-size: 1.1rem;
    line-height: 1.3;
    color: #f1f1f1;
    max-width: 85%;
}
.hedader_bottom_info_box.year{
    justify-content: flex-end;
    color: #f1f1f1;
}

.hedader_bottom_info_text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}


.hedader_bottom_scode{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.hedader_bottom_scode img{
    width: 100%;
    height: auto;
}

/*.hedader_bottom_shin{*/
/*    width: 100%;*/
/*    background: linear-gradient(135deg, #ff0000, #00ff00);*/
/*    animation: background 4s infinite alternate;*/

/*}*/
.hedader_bottom_shin{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f53a5f;
    box-shadow: 0 -120px 70px -220px #b1ef32 inset,
    0 -420px 70px -220px #ce42de inset;
    animation: background 4s infinite alternate;
}
.hedader_bottom_shin img{
    width: auto;
    height: 100%;
    max-height: 80%;
}

/*@keyframes background {*/
/*    0% {*/
/*        background-position: bottom left;*/
/*    }*/
/*    100% {*/
/*        background-position: top right;*/
/*    }*/
/*}*/
@keyframes background {
    50% {
        background: darkviolet;
        box-shadow: 0 -240px 70px -120px mediumorchid inset,
        0 -380px 70px -120px #f54ff5 inset;
    }
}

@media all and (max-width: 480px){
    .hedader_bottom_scode,.hedader_bottom_info_circle_text{
        display: none;
    }
    .hedader_bottom{
        grid-template-columns: 2fr 100px;
        grid-template-rows: auto;
    }
    .hedader_bottom_info_box_text{
        font-size: 1rem;
        max-width: 100%;
    }
    .hedader_bottom_shin img{
        height: auto;
        width: 100%;
    }
}
