.cirkle_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 280px;
}
.cirkle_box_item{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cirkle_1111{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px dashed #0be867;
    margin: 2rem;
    animation: moveCircle 10s linear infinite;
    /*transform: skew(30deg,30deg) rotate(0deg);*/
}
.cirkle_2222{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid #f1f1f1;
    margin: 2rem;
    transform:  skew(40deg,20deg);
}

.cir_1{
    transform: translate(20px,10px);
    z-index: 3;
}
.cir_2{
    transform: translate(0,-175px);
    z-index: 2;
}
.cir_3{
    transform: translate(-20px,-375px);
    z-index: 1;
}

@keyframes moveCircle {
    0% {
        transform: skew(40deg,20deg) rotate(0deg);

    }
    100% {
        transform: skew(40deg,20deg) rotate(360deg);
    }
}

@media all and (max-width: 480px){
    .cirkle_box{
        width: 100%;
        height: 100%;
        max-width: 130px;
        max-height: 130px;
    }
    .cirkle_1111 {
        width: 60px;
        height: 60px;
        border: 2px dashed #0be867;
    }
    .cirkle_2222 {
        width: 60px;
        height: 60px;
        border: 2px solid #f1f1f1;
    }
    .cir_1{
        transform: translate(12px,-3px);
        z-index: 3;
    }
    .cir_2{
        transform: translate(-1px,-115px);
        z-index: 2;
    }
    .cir_3{
        transform: translate(-13px,-223px);
        z-index: 1;
    }
}