.footer{
    background-color: #1F1F1F;
    padding: 2rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #f1f1f1;
}
.footer_logo{
    color: #f1f1f1;
    font-size: 2rem;
    cursor: pointer;
    margin: 0;
}
.footer_reserv{
    color: #f1f1f1;
}
@media all and (max-width: 480px){
    .footer{
        padding: 1rem;
        flex-direction: column;
        align-items: center;
    }
}