.country_select_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.2rem;
}
.dropdown {
    margin: 0 auto;
    z-index: 10000;
    width: 100%;
    padding: 1rem 0 0 0 ;
    position: relative;
}
.dropdown,
.dropdown * {
    z-index: 10;
}

.dropdown-btn .search-icon{
    position: absolute;

}

.search_country_input{
    border: none;
    background: none;
    color: #2de278;
    font-size: 2rem;
    font-weight: bold;
    outline: none;
    width: 100%;
    margin-left: 2rem;
}

.search_country_input::placeholder{
    font-size: 1.6rem;
}

.dropdown .dropdown-btn {
    cursor: pointer;
    background: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #2de278;
    border-radius: 5px;
    color: #2de278;
    font-weight: 500;
    font-size: 1.9rem;
}
.dropdown-content {
    position: absolute;
    /* top: 110%; */
    left: 0;
    width: 100%;
    box-shadow:0 0 10px 5px rgba(255, 255, 255, 0.1);
    background: #1f1f1f;
    max-height: 300px;
    overflow-y: scroll;
}
.dropdown-content .item {
    padding: 12px;
    cursor: pointer;
    /*color: #2de278;*/
    color: #f1f1f1;
    font-size: 1.6rem;
}
.dropdown-content .item:hover {
    background: #fcfcfc;
}

/*button {*/
/*    z-index: -1;*/
/*    display: block;*/
/*    width: 300px;*/
/*    margin: 0 auto;*/
/*}*/

.country_title_info{
    margin: 6px 0;
    color: #2de278;
    font-size: 2rem;
    font-weight: bold;
}

.country_select_info_box{
    width: 100%;
    /*max-width: 300px;*/
    margin: 32px 0 0 0;
}
