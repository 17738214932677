@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;400;500;600;700;800;900&display=swap');
*{
    box-sizing: border-box;
}
body{
    background-color: #1F1F1F;
    font-family: 'Unbounded', cursive;
}

@media all and (max-width: 480px){
    body{
        overflow-x: hidden;
    }
}