.company_contact{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr;
    border-top: 1px solid #f1f1f1;
}

.company_contact_box{
    display: flex;
    align-items: center;
    flex: 45% 1;
    padding: 3rem;
    border-right: 1px solid #f1f1f1;
}

.company_contact_box-title{
    text-transform: uppercase;
    color: #f1f1f1;
    font-size: 3rem;
}

@media all and (max-width: 480px){
    .company_contact{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .company_contact_box{
        padding-bottom: 0;
        border-right: none;

    }
    .company_contact_box-title{
        margin: 0;
        font-size: 2.2rem;
        text-align: center;
    }
}