.counter_info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    background: url("../../images/space.jpg");
    background-size: cover;
}

.counter_info-item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:0 2rem;
    border-right: 1px solid #f1f1f1;
}
.counter_info-item:last-child{
    border-right: none;
    padding-right: 0;
}
.counter_info-item-title{
    font-size: 4rem;
    color: #A1147A;
    font-weight: bold;
    text-transform: uppercase;
}
.counter_info-item-subtitle{
    color: #f1f1f1;
    font-size: 1.2rem;
    text-transform: uppercase;
    min-height: 50px;
    text-align: center;
}
.pink_text{
    color: #A1147A;
    font-size: 1.9rem;
    margin: 0;
}
@media all and (max-width: 480px){
    .counter_info{
        flex-direction: column;
        padding: 1rem;
    }
    .counter_info-item{
        padding: 1rem 0;
        border-right: none;
        border-bottom: 1px solid #f1f1f1;
    }
    .counter_info-item:last-child{
        border-bottom: none;
    }
    .counter_info-item-title{
        font-size: 2rem;

    }
    .counter_info-item-subtitle{
        font-size: 1rem;
    }
    .counter_info-item-subtitle.pink_text{
        font-size: 1.5rem;
    }
}