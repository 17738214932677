.marquee_box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    background-color: #0be867;
}

.marquee_line{
    color: #1F1F1F;
    font-size: 1.5rem;
}

.marquee_line_text{
    margin: 0 20px;
}