.d-none{
    display: none;
}
.advanteges{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    background-color: #1F1F1F;
    min-height: 500px;
    border-bottom: 1px solid #f1f1f1;
}

.advanteges_side-boxtitle{
    border-right: 1px solid #f1f1f1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 4rem 0;
    max-width: 215px;
}

.advanteges_side-title{
    transform: rotate(90deg);
    font-size: 2.4rem;
    color: #f1f1f1;
    text-transform: uppercase;
    margin-left: -30px;


}

.advanteges_main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*max-height: 400px;*/
    width: 100%;
    /*padding: 1rem;*/
}

.slick-slider{
    display: flex!important;
    padding: 3rem 0;
}
.slick-list{
    max-width: 80vw;
}

.advanteges_main_box{
    /*max-width: 500px;*/
    display: grid;
    padding: 2rem;
    border-right: 1px solid #f1f1f1;
    grid-template-rows: 2fr 1fr;
    grid-template-areas:
        'advImg'
        'advTitle';
}

.advanteges_main_box_img{
    grid-area: advImg;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.advanteges_main h2{
    grid-area: advTitle;
    font-size: 1.6rem;
    color: #f1f1f1;
    text-transform: uppercase;
}

.advanteg_main_line{
    height: 88%;
    width: 1px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.advanteges_link{
    background-color: #1F1F1F;
    border: none;
    border-left: 1px solid #f1f1f1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100px;
    height: 100%;
    padding: 1.5rem;
    cursor: pointer;
    outline: none;
}

@media all and (max-width: 480px){
    .advanteges{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: auto;
        padding-bottom: 2.5rem;
    }
    .advanteges_side-boxtitle{
        border-right: none;
        padding: 1.5rem 1rem;
    }
    .advanteges_side-title{
        transform: rotate(0deg);
        font-size: 2.2rem;
        max-width: 100%;
        margin-left: 0;
    }
    .overflow-scrolling-2.advanteges_main{
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        width: 100vw;
        grid-template-columns: 270px auto 270px 100px;

    }
    .advanteges_main_box{
        padding: 1rem;
        grid-template-rows: auto;
        grid-template-areas:
        'advImg . .'
        'advImg . .'
        'advTitle advTitle advTitle';
    }
    .advanteges_main_box_img{
        width: 200px;
        height: 140px;
        align-items: flex-start;
    }
    .advanteges_main_box_img img{
        width: auto;
        max-width: 120px;
        max-height: 145px;
    }
    .advanteges_main h2{
        font-size: 1.4rem;
    }
    .advanteges_link{
        height: 88%;
        border: none;
    }
}
