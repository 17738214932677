.animated_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    background-color: #1F1F1F;
    z-index: 999999999999999;
}

.animated_title{
    overflow : hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.animated_title h1{
    text-align: center;
    font-size: 5rem;
    text-transform: uppercase;
    width: 100%;
    line-height: 1;
}

.animated_title h1 > span{
    color: #A1147A;
}

.animated_title:last-child{
    color: #f1f1f1;

}

@media all and (max-width: 480px) {
    .animated_title h1{
        font-size: 2rem;
    }

}

